function klickartMenuToggle() {
  const headers = document.querySelectorAll(".header-container");

  const toggleOpen = (icon, menu) => {
    const isOpen = icon.classList.contains("open");
    if (isOpen) {
      icon.classList.remove("open");
      menu.style.maxHeight = `${menu.scrollHeight}px`;
      setTimeout(() => {
        menu.style.maxHeight = 0;
      }, 5);
    } else {
      icon.classList.add("open");
      menu.style.maxHeight = `${menu.scrollHeight}px`;
    }
    setTimeout(() => {
      return isOpen
        ? (menu.style.maxHeight = 0)
        : (menu.style.maxHeight = "none");
    }, 250);
  };

  headers.forEach((header) => {
    const icon = header.querySelector(".navicon");
    const menu = header.querySelector(".header-navigation");

    menu.style =
      "overflow: hidden; max-height: 0px; display: block; transition: all 0.25s ease 0s;";

    icon.addEventListener("click", (event) =>
      toggleOpen(event.currentTarget, menu)
    );
  });
}

export default klickartMenuToggle;
